<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="5%"
    width="50%"
    title="新增试卷"
    :center="true"
    :before-close="doClose"
  >
    <div style="height:530px" class="addtestpop">
      <div class="ovy-a">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="12rem"
          class="commodity-form"
        >
          <el-form-item label="试卷名称" prop="paperName" class="form-item">
            <el-input
              v-model="ruleForm.paperName"
              maxlength="50"
              :show-word-limit="true"
              placeholder="请输入试卷名称"
              size="small"
            />
          </el-form-item>
          <el-form-item label="培训类型" prop="Trainingtype" class="form-item">
            <tree
              ref="tree"
              @eventBtn="childBack"
              :ruleForm="ruleForm"
              @clearParams="clearParams"
              :modal="false"
              size="small"
              type
              typeStu
            />
          </el-form-item>
          <el-form-item label="通过分数" prop="paperScore" class="form-item">
            <el-input-number v-model="ruleForm.paperScore" :min="1" size="small" :max="200"></el-input-number>
          </el-form-item>
          <el-form-item label="下载模板" class="form-item">
            <el-button @click="downloadExcel" size="small">下载</el-button>
          </el-form-item>
          <el-form-item label="上传试题" prop="fileName">
            <el-upload
              v-model="ruleForm.fileName"
              v-if="!ruleForm.fileName"
              class="upload-demo upload-btn"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              :show-file-list="false"
              :auto-upload="false"
            >
              <el-button size="small" class="bgc-bv">选取试题</el-button>
            </el-upload>
            <span v-else>
              {{ ruleForm.fileName
              }}
              <a @click="reomveExl" style="color: #5bb5ff">删除</a>
            </span>
          </el-form-item>
          <el-form-item label=" " class="form-item form-item-hint">
            <p>只能上传.XLS 和 .XLSX格式的单个文件，且文件大小不超过1MB！</p>
          </el-form-item>
          <el-form-item label="是否发布在平台销售：" prop="salesState" class="form-item form">
            <div>
              <el-radio-group v-model="ruleForm.salesState">
                <el-radio :label="20">是</el-radio>
                <el-radio :label="10">否</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        </el-form>
        <div class="btnBox">
          <el-button class="bgc-bv" @click="formCancel('ruleForm')">取消</el-button>
          <el-button class="bgc-bv" @click="formSave('ruleForm')">保存</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
import tree from "@/components/treePopup";
export default {
  name: "BusinessAdd",
  components: {
    tree
  },
  mixins: [UploadPic, DatePicker, Form],
  data() {
    return {
      dialogVisible: false,
      actionUrl: "",
      ruleForm: {
        id: null,
        paperName: "",
        paperScore: 60,
        excelUrl: "",
        salesState: 10,
        Trainingtype: "",
        fileName: ""
      },
      datatreeList: [],
      props: {
        value: "id",
        label: "label"
      },
      //是否上传文件
      updateFile: false,
      rules: {
        paperName: [
          { required: true, message: "请输入试卷名称", trigger: "blur" }
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        paperScore: [
          { required: true, message: "请输入通过分数", trigger: "blur" }
        ],
        salesState: [
          { required: true, message: "请选择销售状态", trigger: "blur" }
        ],
        fileName: [{ required: true, message: "请上传试卷", trigger: "blur" }]
      },
      params: {}
    };
  },
  computed: {},
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    init() {
      const query = this.$route.query;
      this.ruleForm.id = query.id;
    },
    // 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/paper.xls";
      link.setAttribute("download", "paper.xls");
      document.body.appendChild(link);
      link.click();
    },
    doAjax(formName) {
      const that = this;
      const ruleForm = that.ruleForm || {};
      let parmars = {
        ...ruleForm,
        paperOrgin: 10
      };
      // 培训类型
      if (that.params.trainFormId) {
        parmars.trainTypeId = that.params.trainFormId;
      }
      if (that.params.levelFormId) {
        parmars.trainLevelId = that.params.levelFormId;
      }
      if (that.params.occFormId) {
        parmars.occupationId = that.params.occFormId;
      }
      if (that.params.postFormId) {
        parmars.postId = that.params.postFormId;
      }
      if (that.params.industryFormId) {
        parmars.industryId = that.params.industryFormId;
      }
      if (that.ruleForm.excelUrl == "") {
        that.$message.warning("请先上传试题");
        return false;
      }
      that
        .$post("/biz/paper/insertPaperQuestion", parmars)
        .then(res => {
          that.$emit("saved");
          setTimeout(() => {
            that.$message({
              type: "success",
              message: "保存成功"
            });
            that.dialogVisible = false;
            that.ruleForm.salesState = 10;
            that.checkShow = false;
            that.checked = false;
          }, 300);
          that.$refs[formName].resetFields();
          this.$emit("getList");
          this.$emit("childback", res.data.paperId, res.data.paperName);
        })
        .catch(err => {
          console.log(err);
            that.$message({
              type: "error",
              message: err.data.message
            });
          return;
        });
    },

    formCancel(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
      // this.$router.back();
    },
    /**
     *
     */
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.excelUrl = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    showPopUp(params, ruleForm) {
      this.dialogVisible = true;
      this.params = { ...params };
      // this.ruleForm.Trainingtype = ruleForm.Trainingtype;
    }
  }
};
</script>
<style lang="less">
.addtestpop {
  .upload-btn {
    width: 5rem;
    height: 40px;
    .el-upload {
      width: 5rem;
      height: 40px !important;
      border: none !important;
    }
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
