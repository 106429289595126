<template>
  <div class="pageContol formBOX">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">我的资源</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的课程/课件</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">{{ ruleForm.id ? "修改" : "" }}上传课件</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="12rem" class>
              <el-form-item label="课件名称" prop="Coursewarename" class="form-item">
                <el-input v-model="ruleForm.Coursewarename" placeholder="请输入课件名称" size="small" />
              </el-form-item>
              <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
                <tree
                  ref="tree"
                  @eventBtn="childBack"
                  :ruleForm="ruleForm"
                  @clearParams="clearParams"
                  type
                  typeStu
                />
              </el-form-item>
              <el-form-item label="课件学时" prop="Coursewarenum" class="form-item">
                <el-input-number
                  v-model="ruleForm.Coursewarenum"
                  :step="0.1"
                  :precision="1"
                  @change="handleChange"
                  :min="0.1"
                  size="small"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="关联讲师" prop="teachername" class="form-item form-itemteache">
                <el-select 
                    v-model="ruleForm.teachername" 
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择讲师">
                  <el-option
                    v-for="item in teachertree"
                    :key="item.teacherQualificationId"
                    :label="item.teacherQualificationName"
                    :value="item.teacherQualificationId">
                    <div style="display: flex;justify-content:space-between;align-items:center">
                      <span>{{ item.teacherQualificationName }}</span>
                      <el-popover
                        placement="right"
                        v-if="item.teacherQualificationId!= '-1000'"
                        trigger="hover">
                        <el-button type="text" slot="reference">证书</el-button>
                        <el-image
                          style="width: 500px; height: auto;"
                          fit="contain"
                          :src="item.qualificationImageUrl">
                        </el-image>
                      </el-popover>
                    </div>
                  </el-option>
                </el-select>
                <el-button class="bgc-bv" style="margin-left:10px" size="small" @click="addteacher()">新增讲师</el-button>
              </el-form-item>
              <el-form-item label="课件ID：" prop="coursewareId" class="form-item">
                <el-input v-model="ruleForm.coursewareId" placeholder="请输入课件ID" size="small" />
              </el-form-item>
               <el-form-item label="上传字幕文件：" prop="fileName" class="form-item fileNameUpload">
               <el-upload
                  v-model="ruleForm.fileName"
                  v-if="!ruleForm.fileName"
                  class="upload-demo upload-btn"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :auto-upload="false"
                >
                  <el-button size="small" class="bgc-bv">上传字幕文件</el-button>
                </el-upload>
                <span style="color:#f46173" v-else>
                  {{ ruleForm.fileName }}
                  <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                </span>
              </el-form-item>
              <el-form-item label="课后习题：" prop="Exercises" class="form-item form-itemteache">
                <el-select
                  v-model="ruleForm.Exercises"
                  filterable
                  size="small"
                  placeholder="请选择"
                  style="width:100%"
                  @change="selechange"
                >
                  <el-option
                    v-for="item in ExercisesList"
                    :key="item.paperId"
                    :label="item.paperName"
                    :value="item.paperId"
                  ></el-option>
                </el-select>
                <el-button
                  class="bgc-bv"
                  style="margin-left: 10px;"
                  size="small"
                  v-show="ruleForm.Exercises"
                  @click="lookExercises(ruleForm.Exercises)"
                >预览</el-button>
                <el-button class="bgc-bv" size="small" @click="addExercises()">新增</el-button>
              </el-form-item>
              <el-form-item
                label="课件封面："
                prop="thumbnail"
                :class="[ruleForm.id ? 'item-disabled' : '','form-item']"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label=" " class="form-item form-item-hint">
                <p>建议上传尺寸224*160。</p>
              </el-form-item>
               <el-form-item label="课件描述：" class="form-item form">
                  <div class="flexdc">
                        <el-input v-model="ruleForm.kpointDescribe" :rows="6" class="input_w_80" maxlength="500" show-word-limit resize="none"
                                  type="textarea"></el-input>
                      </div>
              </el-form-item>
               <el-form-item label="课件纲要：" class="form-item form">
                  <div class="flexdc">
                        <el-input v-model="ruleForm.kpointOutline" :rows="6" class="input_w_80" maxlength="500" show-word-limit resize="none"
                                  type="textarea"></el-input>
                      </div>
              </el-form-item>
              <el-form-item label="是否发布在平台销售：" class="form-item form">
                <div>
                  <el-radio-group v-model="radio" @change="radioChange">
                    <el-radio :label="20">是</el-radio>
                    <el-radio :label="10">否</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item class="form-btn">
                <el-button class="bgc-bv" @click="Cancel">取消</el-button>
                <el-button class="bgc-bv" @click="formSave('ruleForm')">保存</el-button>
                <el-button class="bgc-bv" @click="formSave('ruleForm', '20')">保存并提交审核</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <Teacherpop ref="Teacherpop" @getList="getteachertree" @childback1="childback1"/>
    <addtestpop @childback="childback" ref="addtestpop" @getList="getExercises" />
     <PaperShow ref="PaperShow" />
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import Form from "@/mixins/Form";
import Teacherpop from "@/views/resourse/popup/Teacherpop";
import addtestpop from "@/views/resourse/popup/addtestpop";
import tree from "@/components/treePopup";
import PaperShow from "@/views/resourse/popup/PaperShow";
export default {
  name: "AddExistingCourseware",
  mixins: [UploadPic, DatePicker, Form],
  components: {
    Teacherpop,
    addtestpop,
    tree,
    PaperShow
    // uploadAuth
  },
  data() {
    return {
      ruleForm: {
        coursewareId: "",
        teachername: "",
        Coursewarename: "",
        Trainingtype: "",
        Coursewarenum: "1",
        thumbnail: "",
        fileKey: "",
        paperId: "",
        paperName: "",
        kpointDescribe:'',
        kpointOutline:'',
        subtitleKey:'',
        fileName:'',
      },
      rules: {
        Coursewarename: [
          { required: true, message: "请输入课件名称", trigger: "blur" }
        ],
        Trainingtype: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        Coursewarenum: [
          { required: true, message: "请输入课件学时", trigger: "blur" }
        ],
        teachername: [
          { required: true, message: "请选择讲师", trigger: "change" }
        ],
        coursewareId: [
          { required: true, message: "请输入课件Id", trigger: "blur" }
        ]
      },
      ExercisesList: [],
      datatreeList: [],
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      videoId: "",
      teachertree: [],
      radio: 10,
      params: {},
      ruleForm1: {},
      params1: {}
    };
  },
  computed: {},
  created() {
    this.getteachertree();
    this.getExercises();
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
      this.params1 = { ...params };
      this.ruleForm1 = { ...this.ruleForm };
    },
    // 试题回调
    childback(paperId, paperName) {
      this.ruleForm.Exercises = paperId;
      this.ruleForm.paperName = paperName;
    },
     childback1(teacherQualificationId) {
      this.ruleForm.teachername = teacherQualificationId;
    },
    clearParams() {
      this.params = {};
    },
    init() {
      // this.getList();
    },
    vedioId(videoId) {
      this.videoId = videoId;
    },
    getteachertree() {
      this.$post("/biz/teacher/queryQualificationDictionary", {}).then(res => {
        if (res.status == 0) {
         this.teachertree = [...res.data,{teacherQualificationId:'-1000',teacherQualificationName:'无讲师'}]
        }
      });
    },
    handlecascader(val) {
      this.ruleForm.Trainingtype = val;
    },
    handleteacher(val) {
      this.ruleForm.teachername = val;
    },
    getExercises() {
      this.$post("/biz/paper/queryPaperComboBox", {}).then(ret => {
        if (ret.status == 0) {
          this.ExercisesList = ret.data;
        }
      });
    },
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.thumbnail = result.data.fileURL;
          this.ruleForm.fileKey = result.data.fileKey;
          console.log(result);
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败"
            });
          }, 300);
        });
    },
    selechange(val) {
      for (let i = 0; i < this.ExercisesList.length; i++) {
        let obj = this.ExercisesList.find(item => {
          return item.paperId === val;
        });
        this.ruleForm.paperName = obj.paperName;
        this.ruleForm.paperId = obj.paperId;
      }
    },
    // 保存
    formSave(formName = "ruleForm", type) {
      this.ruleForm.type = type;
      const el = this.$refs[formName];
      el.validate(valid => {
        if (valid) {
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    doAjax() {
      const parmars = {
        kpointName: this.ruleForm.Coursewarename,
        // trainTypeId: this.ruleForm.Trainingtype,
        kpointLessonNum: this.ruleForm.Coursewarenum,
        kpointVideoId: this.ruleForm.coursewareId,
        qualificationId: this.ruleForm.teachername,
        salesState: this.radio
      };
      if (this.ruleForm.fileKey) {
        parmars.kpointLogo = this.ruleForm.fileKey;
      }
       if (this.ruleForm.kpointDescribe) {
        parmars.kpointDescribe = this.ruleForm.kpointDescribe;
      }
       if (this.ruleForm.kpointOutline) {
        parmars.kpointOutline = this.ruleForm.kpointOutline;
      }
      if (this.ruleForm.paperName) {
        parmars.paperName = this.ruleForm.paperName;
        parmars.paperId = this.ruleForm.Exercises;
      }
       if (this.ruleForm.subtitleKey) {
        parmars.subtitleFile = this.ruleForm.subtitleKey;
        parmars.subtitleFileName = this.ruleForm.fileName;
      }
      if (this.ruleForm.type) {
        parmars.auditState = this.ruleForm.type;
      }
      // 培训类型 trainForm postForm occForm levelForm industryForm
      if (this.params.trainFormId) {
        parmars.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmars.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmars.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmars.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmars.industryId = this.params.industryFormId;
      }
      this.$post("/biz/courseware/insert", parmars)
        .then(() => {
          setTimeout(() => {
            this.$message({
              type: "success",
              message: "保存成功"
            });
            this.Cancel("1");
          }, 300);
        })
        .catch(() => {
          return;
        });
    },
    addteacher() {
      this.$refs.Teacherpop.showPopUp();
    },
    addExercises() {
      this.$refs.addtestpop.showPopUp(this.params1, this.ruleForm1);
    },
    Cancel(isRefresh) {
      this.$router.push({
        name: "myresourseList",
        params: {
          refresh: isRefresh == "1" ? true : false,
          refName: "second",
          isAdd: isRefresh == "1" ? true : false
        }
      });
    },
    lookExercises(Exercises) {
      this.$refs.PaperShow.showPopUp(Exercises); // 打开弹窗预览试题
    },
     //上传字幕文件
      uploadChange(file) {
      let size = file.size / 1024 / 1024;
       let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isSrt= extension === "srt";
      if (!isSrt) {
        this.$message.error("只能上传后缀是.srt的文件");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.subtitleKey = result.data.fileKey;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.subtitleKey = "";
      this.actionUrl = "";
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="less" >
.formBOX {
  .el-form-item__content {
    width: 50%;
  }
  .el-input-number {
    width: 100%;
  }
  .form-itemteache {
    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }
  .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button {
      width: 10rem;
    }
  }
}
.el-cascader {
  width: 100% !important;
}
.el-icon-arrow-down {
  font-size: 12px;
  &:before {
    content: "\e6df" !important;
  }
}
.fileNameUpload {
  .upload-btn {
  height: 40px !important;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
}
</style>
